import React from 'react'
import Example from 'components/Example'
import FeedbackButton from 'components/FeedbackButton'
import Sector from 'components/Sector'
import bem from 'bem'
import css from './Examples.module.scss'

const b = bem.examples(css)

const Examples = () => {
	return (
		<div className={b()}>
			<Sector title="Скачать примеры" color="gray" titleCentered>
				<p className="fs-h4 text-center mb">Демо-версии данных с популярных площадок</p>
				<div className={b('files', 'mb-lg')}>
					<div className={b('file')}>
						<Example
							icon="wildberries"
							url="/s/examples/2019-07-29_wildberries.xlsx"
							title="Wildberries"
							description="Коллекции одежды, обуви, товаров для дома и&nbsp;спорта"
							tag="a"
						/>
					</div>
					<div className={b('file')}>
						<Example
							icon="apteka"
							url="/s/examples/2019-08-21_apteka.xlsx"
							title="apteka.ru"
							description="Каталог лекарств, инструкций, отзывов и&nbsp;советов врачей"
							tag="a"
						/>
					</div>
					<div className={b('file')}>
						<Example
							icon="cian"
							url="/s/examples/2019-08-08_cian.xlsx"
							title="Циан"
							description="Объявления об&nbsp;аренде и&nbsp;продаже объектов недвижимости"
							tag="a"
						/>
					</div>
					<div className={b('file')}>
						<Example
							icon="market"
							url="/s/examples/2019-08-02_market.xlsx"
							title="Яндекс маркет"
							description="Сервис с&nbsp;огромным ассортиментом товаров от&nbsp;20&nbsp;тысяч магазинов"
							tag="a"
						/>
					</div>
				</div>
				<p className="fs-h4 text-center mb">Сайты клиентов</p>
				<div className={b('sites', 'mb-lg')}>
					<div className={b('site')}>
						<Example
							icon="oskolenergo"
							url="https://www.shop-oskolenergo.ru"
							title="Интернет-магазин котельного оборудования и сопутствующих товаров"
							description="Мониторинг цен нескольких сайтов-доноров котельного оборудования. Наш клиент использует эти данные для установки самой выгодной цены для конечного клиента"
							type="inline"
							tag="pseudo-link"
						/>
					</div>
					<div className={b('site')}>
						<Example
							icon="serpure"
							url="https://www.serpure.com/ru"
							title="Инструмент для SEO-оптимизации заголовков и описаний сайта в Google"
							description="Интересный и сложный парсинг выдачи Google с обходом защиты капчи с использованием прокси-серверов и симуляции пользовательской активности"
							type="inline"
							target="_blank"
							tag="a"
						/>
					</div>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Заказать парсинг
					</FeedbackButton>
				</div>
			</Sector>
		</div>
	)
}

export default Examples
